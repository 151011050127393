import { defineMessages } from "react-intl";

export default defineMessages({
    "freeDownload": {
        id: "request-form.freeDownload",
        defaultMessage: "Download gratuito"
    },
    "downloadFailedTitle": {
        id: "request-form.downloadFailedTitle",
        defaultMessage: "OPS..."
    },
    "downloadFailedMessage": {
        id: "request-form.downloadFailedMessage",
        defaultMessage: "Qualcosa non ha funzionato.."
    },
    "downloadSucceededTitle": {
        id: "request-form.downloadSucceededTitle",
        defaultMessage: "GRAZIE"
    },
    "downloadSucceededMessage": {
        id: "request-form.downloadSucceededMessage",
        defaultMessage: `
Grazie per aver compilato la form. 
Ti abbiamo inviato la Case History all'indirizzo email inserito.
`
    },
    "mandatoryField": {
        id: "request-form.mandatoryField",
        defaultMessage: "* campo obbligatorio"
    },
    "newsletterAccept": {
        id: "request-form.newsletterAccept",
        defaultMessage: "Accetto la"
    },
    "privacyPolicyLabel": {
        id: "request-form.privacyPolicyLabel",
        defaultMessage: "Privacy policy"
    },
    "buttonDownload": {
        id: "request-form.buttonDownload",
        defaultMessage: "Scarica"
    },
    "buttonDownloadAgain": {
        id: "request-form.buttonDownloadAgain",
        defaultMessage: "Riprova"
    },
    "namePlaceholder": {
        id: "request-form.namePlaceholder",
        defaultMessage: "Nome*"
    },
    "surnamePlaceholder": {
        id: "request-form.surnamePlaceholder",
        defaultMessage: "Cognome*"
    },
    "emailPlaceholder": {
        id: "request-form.emailPlaceholder",
        defaultMessage: "Email*"
    },
    "companyPlaceholder": {
        id: "request-form.companyPlaceholder",
        defaultMessage: "Azienda*"
    },
    "rolePlaceholder": {
        id: "request-form.rolePlaceholder",
        defaultMessage: "Ruolo*"
    },
    "sectorPlaceholder": {
        id: "request-form.sectorPlaceholder",
        defaultMessage: "Settore*"
    },
    "iubendaID": {
        id: "app.iubendaID",
        defaultMessage: "8258032"
    },
    "smileAlt": {
        id: "app.smileAlt",
        defaultMessage: "smile image"
    },
    "arrowAlt": {
        id: "app.arrowAlt",
        defaultMessage: "Close"
    },
    "buttonFindOutMore": {
        id: "request-form.buttonFindOutMore",
        defaultMessage: "Scopri di più"
    },
});
