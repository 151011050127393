export default [
    {
        "label": "Automotive"
    },
    {
        "label": "Banking"
    },
    {
        "label": "Biotechnology "
    },
    {
        "label": "Consulting "
    },
    {
        "label": "Finance"
    },
    {
        "label": "Gaming"
    },
    {
        "label": "GDO"
    },
    {
        "label": "Industry"
    },
    {
        "label": "Information Technology"
    },
    {
        "label": "Insurance"
    },
    {
        "label": "Manufacturing "
    },
    {
        "label": "Utilities"
    },
    {
        "label": "Retail "
    },
    {
        "label": "Other"
    }
];