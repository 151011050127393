import React from 'react';
import appMessages from '../../App.messages';
import { useIntl } from 'react-intl';

const FindOutMoreButton = ({ findOutMoreLink }) => {
    const { formatMessage } = useIntl();

    return <div className="button-container" >
        <div className={"btn button"} id="find-out-more-form-button">
        <a target="_blank" href={findOutMoreLink}>
            {formatMessage(appMessages.buttonFindOutMore)}
         </a>
        </div>
    </div>
}

export default FindOutMoreButton;