export default [
    {
        id: "easyaccess",
        img: "easyaccess",
        pdf: "/projects/easyaccess.pdf",
        titolo: "EASYACCESS",
        sottotitolo: "Cefla",
        descrizione: "Gestione e controllo dei macchinari medicali.",
        findOutMoreLink: "https://gellify.com/news-and-events/manutenzione-dei-macchinari-medicali-il-caso-cefla/"
    },
    {
        id: "VALENTINA",
        img: "valentina",
        pdf: "/projects/valentina.pdf",
        titolo: "VALENTINA",
        sottotitolo: "BPER",
        descrizione: "Progetto di intelligenza artificiale applicata a un assistente virtuale per il digital banking.",
        findOutMoreLink: "https://gellify.com/news-and-events/bper-valentina-lassistente-bancario-virtuale/"
    },
    {
        id: "ESS",
        img: "scenario",
        pdf: "/projects/ess.pdf",
        titolo: "ECONOMIC SCENARIO SERVICE",
        sottotitolo: "Prometeia",
        descrizione: "Un servizio per integrare la gestione di scenari di mercato all'interno dei propri processi decisionali.",
        findOutMoreLink: "https://gellify.com/news-and-events/economic-scenario-service-il-caso-prometeia/"
    },
    {
        id: "adequacy",
        img: "adequacy",
        pdf: "/projects/adequacy.pdf",
        titolo: "ADEQUACY",
        sottotitolo: "Creval",
        descrizione: "Servizio per la misurazione dell’adeguatezza complessiva del sistema bancario.",
        findOutMoreLink: "https://gellify.com/news-and-events/creval-adequacy-misurare-ladeguatezza-delle-banche/"
    }
];
