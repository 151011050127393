import React from 'react';
import FindOutMoreButton from '../../components/buttons/find-out-more-button';

const ProjectItem = ({ value, index }) => {
    const imgSet = `/projects/${value.img}.png, /projects/${value.img}@2x.png, /projects/${value.img}@3x.png`;
    const imgSrc = `${value.img}@3x.png`;

    return (
        <div key={index} className="project col-12 col-sm-3 col-md-3 col-lg-3">
            <div className="imgContainer">
                <div href={value.pdf}>
                    <img className="project-img" srcSet={imgSet}
                        src={imgSrc} alt={value.titolo} />
                </div>
                <div className="overlay-box"></div>
            </div>
            <h3>{value.titolo}</h3>
            <h4>{value.sottotitolo}</h4>
            <p className="content">{value.descrizione}</p>

            <FindOutMoreButton findOutMoreLink={value.findOutMoreLink}/>
        </div>
    );
}

export default ProjectItem;