export default [
    {
        "label": "CEO - Chief Executive Officer"
    },
    {
        "label": "CFO - Chief Financial Officer"
    },
    {
        "label": "CTO - Chief Technology Officer"
    },
    {
        "label": "CHRO - Chief Human Resources Officer"
    },
    {
        "label": "CMO - Chief  Marketing Officer"
    },
    {
        "label": "CCO - Chief Customer Officer"
    },
    {
        "label": "CDO - Chief Data Officer"
    },
    {
        "label": "CIO - Chief Information Officer"
    },
    {
        "label": "COO - Chief Operating Officer"
    },
    {
        "label": "HR"
    },
    {
        "label": "Innovation"
    },
    {
        "label": "IT Manager"
    },
    {
        "label": "Marketing"
    },
    {
        "label": "Presidente"
    },
    {
        "label": "Product Manager"
    },
    {
        "label": "Sales"
    }
];