import React, { useState } from "react";
import { useIntl } from "react-intl";

import rawRoles from '../../data/roles';
import rawSectors from '../../data/sectors';

import crossImage from '../../assets/images/cross.svg'
import arrowImage from '../../assets/images/arrow-orange.svg'

import DownloadSuccessSection from "./download-success";
import DownloadErrorSection from "./download-error";
import DownloadButton from "../../components/buttons/download-button";
import Dropdown from "../../components/dropdown/dropdown";

import appMessages from '../../App.messages';
import ReactGA from "react-ga";

import './download-form.scss';
import apiService from "../../api/api-service";
// import { parseFormDataToRequestBody } from "../../api/parser";
import { formDataToJson } from "../../utils/utils";

const roles = rawRoles.map(role => role.label);
const sectors = rawSectors.map(sector => sector.label);


const sendEvent = (pdfName) => {
    ReactGA.event({ category: 'Link', action: 'Open PDF', label: pdfName })
}

const DownloadForm = ({ show, close, formAction, selectedProjectId }) => {

    const { formatMessage } = useIntl();

    const [isSuccessSectionShown, setSuccessSectionShown] = useState(false);
    const showSuccessSection = () => setSuccessSectionShown(true);

    const [isErrorSectionShown, setErrorSectionShown] = useState(false);
    const [isSendingRequest, setIsSendingRequest] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        setIsSendingRequest(true);


        const formData = new FormData(e.target);
        const parsedFormData = formDataToJson(formData);
        parsedFormData.case = selectedProjectId;
        apiService.submitFormData(parsedFormData, formAction)
            .then(_ => {
                sendEvent(selectedProjectId);
                showSuccessSection();
                setIsSendingRequest(false);
            })
            .catch(() => {
                setErrorSectionShown(true);
                setIsSendingRequest(false);
            });
        /*
    const user = parseFormDataToRequestBody(parsedFormData, selectedProjectId);

    apiService.sendMail(user).then((resp) => {
        sendEvent(selectedProjectId);
        showSuccessSection();
        setIsSendingRequest(false);
    }).catch((e) => {
        setErrorSectionShown(true);
        setIsSendingRequest(false);
    });*/
    }

    const retrySubmit = () => setErrorSectionShown(false);

    const restoreAndClose = () => {
        setTimeout(() => {
            setSuccessSectionShown(false);
            setErrorSectionShown(false);
        }, 200);
        close();
    }


    return <aside className={`modal-overlay${show ? '' : ' hidden'}`} id="modal-download-tools">
        <div className="modal-outside"></div>
        <div className="modal-wrapper">
            <div className="modal-close justify-content-center justify-content-md-start" onClick={restoreAndClose}>
                <img src={crossImage} className="icon" alt="Close" />
                <span>
                    Close
            </span>
            </div>
            <div className="modal-container">
                <section className="modal" id="download-modal">
                    <div className="modal-content">
                        {!isSuccessSectionShown && !isErrorSectionShown &&
                            <div className="download-modal-section" id="download-form-container">
                                <div className="row">
                                    <div className="col-12">
                                        <h1>{formatMessage(appMessages.freeDownload)}</h1>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <form className="download-modal" action={formAction} id="download-form" onSubmit={onSubmit}>
                                            <div>
                                                <input type="text" rel="1" name="name" id="name"
                                                    placeholder={formatMessage(appMessages.namePlaceholder)} maxLength="200" required="required" />
                                            </div>
                                            <div>
                                                <input type="text" rel="2" name="surname" id="surname"
                                                    placeholder={formatMessage(appMessages.surnamePlaceholder)} maxLength="200" required="required" />
                                            </div>
                                            <div>
                                                <input type="email" name="email" id="email"
                                                    placeholder={formatMessage(appMessages.emailPlaceholder)} required="required" />
                                            </div>
                                            <div>
                                                <input type="text" rel="3" name="company" id="company"
                                                    placeholder={formatMessage(appMessages.companyPlaceholder)} maxLength="200" required="required" />
                                            </div>
                                            <Dropdown placeholder={formatMessage(appMessages.rolePlaceholder)} items={roles} name="role" required="required" />
                                            <Dropdown placeholder={formatMessage(appMessages.sectorPlaceholder)} items={sectors} name="sector" required="required" />
                                            <span className="mandatory-field-label">
                                                {formatMessage(appMessages.mandatoryField)}
                                            </span>
                                            <div className="checkbox">
                                                <input id="6" type="checkbox" name="privacy" required="required" />
                                                <label htmlFor="6"> {formatMessage(appMessages.newsletterAccept)}&nbsp;
                                                <a target="_blank" href={`https://www.iubenda.com/privacy-policy/${formatMessage(appMessages.iubendaID)}`}
                                                        rel="noopener noreferrer"> {formatMessage(appMessages.privacyPolicyLabel)}</a> *</label>
                                            </div>
                                            <button type="submit" name="submit" value="true" disabled={isSendingRequest}>
                                                <DownloadButton disabled={isSendingRequest} />
                                            </button>
                                            <input type="hidden" name="list" value="1" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        }

                        {isSuccessSectionShown &&
                            <DownloadSuccessSection />}
                        {isErrorSectionShown &&
                            <DownloadErrorSection retry={retrySubmit} />}

                        <img src={arrowImage} className="close-arrow"
                            alt={formatMessage(appMessages.arrowAlt)} onClick={restoreAndClose} />
                    </div>

                    <div className="modal-navigation modal-close">
                        {/*</div><svg className="icon icon-cross ">
                             </svg><use xlink:href="/assets/svg/sprite.symbol.svg#cross"></use></svg> 
*/}
                    </div>

                </section >
            </div >
        </div >
    </aside >
}

export default DownloadForm;