
class ApiService {
    sendMail(user) {
        return fetch(`/.netlify/functions/node-fetch/download-case`, {
            method: 'POST',
            body: JSON.stringify(user)
        }).then(response => {
            if (response.ok)
            {
                return response.json();
            } else
            {
                return Promise.reject();
            }
        }).then(json => {
            if (json.error !== undefined)
            {
                return Promise.reject();
            }
            return json;
        });
    }


    submitFormData(data, formAction) {

        return fetch(`/.netlify/functions/node-fetch/submit-form`, {
            method: 'POST',
            body: JSON.stringify({ formAction: formAction, formData: data })
        }).then(response => {
            if (response.ok)
            {
                return response.json();
            } else
            {
                return Promise.reject();
            }
        }).then(json => {
            if (json.error !== undefined)
            {
                return Promise.reject();
            }
            return json;
        });
    }
}

export default new ApiService();