
export default [
    "alfasigma",
    "banca-popolare-sondrio",
    "bper",
    "carserver",
    "cefla",
    "creval",
    "crif",
    "admiral",
    "rekeep",
    "finantix",
    "intesa-san-paolo",
    "ior",
    "laverdi",
    "piccolo",
    "segugio",
    "luxottica",
    "vivaticket",
    "prometeia"
];
